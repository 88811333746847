import { postRequest, getRequest } from "../../helpers/Requests";

//LOGGED
export const signIn = (status) => {
	return {
		type: 'LOGGED',
		payload: status
	};
};

//COMPANIES
export const getCompaniesAction = (data) => {
	return {
		type: "GET_COMPANIES",
		payload: data
	};
};
export function fetchCompanies() {
	return (dispatch) => {
		dispatch({ type: 'GET_COMPANIES', payload: [], loading: true });
		getRequest('companies', getCompaniesAction, dispatch)
	}
}

//USERS
export const getUsersAction = (data) => {
	return {
		type: "GET_USERS",
		payload: data
	}
}
export function fetchUsers() {
	return (dispatch) => {
		dispatch({ type: 'GET_USERS', payload: [], loading: true });
		getRequest('users', getUsersAction, dispatch)
	}
}

//TEAMS
export const getTeamsAction = (data) => {
	return {
		type: "GET_TEAMS",
		payload: data
	}
}
export function fetchTeams() {
	return (dispatch) => {
		dispatch({ type: 'GET_TEAMS', payload: [], loading: true });
		getRequest('teams', getTeamsAction, dispatch);
	}
}

//DEPARTMENT
export const getDepartments = (data) => {
	return {
		type: "GET_DEPARTMENTS",
		payload: data
	}
}
export function fetchDepartments() {
	return (dispatch) => {
		dispatch({ type: 'GET_DEPARTMENTS', payload: [], loading: true });
		getRequest('departments', getDepartments, dispatch);
	}
}

//Statuses
export const statuses = (data) => {
	return {
		type: 'GET_STATUSES',
		payload: data
	}
}
export function fetchStatuses() {
	return (dispatch) => {
		dispatch({ type: 'GET_STATUSES', payload: [], loading: true });
		getRequest('statuses', statuses, dispatch)
	}
}
//End of Statuses

//TICKETS
export const getTickets = (data) => {
	return {
		type: 'GET_TICKETS',
		payload: data
	}
}
export function fetchTickets(queryParams = '') {
	return (dispatch) => {
		dispatch({ type: 'GET_TICKETS', payload: [], loading: true });
		getRequest(`tickets?${queryParams}`, getTickets, dispatch);
	};
}

//TICKETS SEARCH
export const searchAction = (tickets) => {
	return {
		type: 'SEARCH',
		payload: tickets
	}
}
export function searchTickets(val, options) {
	return (dispatch) => {
		dispatch({ type: 'SEARCH', payload: [], loading: true });
		getRequest(`tickets?text=${val}`, searchAction, dispatch, options)
	}
}

//TICKETS FILTER
export function filterAllTickets(tickets) {
	return {
		type: 'FILTER',
		payload: tickets
	}
}
export function getAllFilteredTickets(id) {
	return (dispatch) => {
		dispatch({ type: 'FILTER', payload: [], loading: true });
		getRequest(`tickets?priority=${id}`, filterAllTickets, dispatch)
	}
}

//TICKET DETAILS
export const getDetailTicket = (data) => {
    console.log('getDetailTicket', data);
	return {
		type: 'GET_DETAIL_TICKET',
		payload: {...data}
	}
}

export function fetchDetailTicket(id) {
	return (dispatch) => {
		dispatch({ type: 'GET_DETAIL_TICKET', payload: {}, loading: true });
		getRequest(`ticket/${id}`, getDetailTicket, dispatch)
	}
}
export const clearData = () => {
	return {
		type: 'CLEAR'
	}
}
//END OF TICKET DETAILS

//TICKET NOTIFICATION
export const getNewTicket = (data) => {
	return {
		type: 'GET_NOTIFICATION_TICKET',
		payload: data
	}
}
export function fetchNotificationTicket() {
	return (dispatch) => {
		dispatch({ type: 'GET_NOTIFICATION_TICKET', payload: [], loading: true });
		getRequest('unread-notifications', getNewTicket, dispatch)
	}

}
// END OF TICKET NOTIFICATION

//GET USER
export const setLoading = (loading) => {
    return {
        type: 'SET_USER_LOADING',
        loading
    }
}
export const getUser = (data) => {
	return {
		type: 'GET_USER',
		payload: data
	}
}
let lastUserDispatchTime = 0;
export function fetchUser() {
    return (dispatch) => {
        const currentTime = Date.now();
        if (currentTime - lastUserDispatchTime > 1000) { // debounce in 1 second
            dispatch(setLoading(true));
            getRequest(`user?timezone=${encodeURIComponent(Intl.DateTimeFormat().resolvedOptions().timeZone)}`, getUser, dispatch);
            lastUserDispatchTime = currentTime;
        }
    }
}
//END OF GET USER

//GET IMG LOGO
export const getLogo = (file) => {
	return {
		type: 'GET_IMG_LOGO',
		payload: file
	}
}
export function getImgUrlLogo(e) {
	return (dispatch) => {
		const files = e.target.files;
		const file = URL.createObjectURL(files[0])
		dispatch(getLogo(file))
	}
}
//END OF GET IMG FavICon

//GET IMG LOGO
export const getFavicon = (file) => {
	return {
		type: 'GET_IMG_FAVICON',
		payload: file
	}
}
export function getImgUrlFavicon(e) {
	return (dispatch) => {
		const files = e.target.files;
		const file = URL.createObjectURL(files[0])
		dispatch(getFavicon(file))
	}
}
//END OF GET IMG  FavICon

//PROFILE IMAGE
export const getProfileImage = (file) => {
	return {
		type: 'GET_PROFILE_IMAGE',
		payload: file
	}
}
export function getProfileImg(e) {
	return async (dispatch) => {
		const files = e.target.files;
		const file = URL.createObjectURL(files[0])
		await dispatch(getProfileImage(file))
	}
}
//END OF PROFILE IMAGE

//GENERAL SETTINGS
export const generalAction = (data) => {
	return {
		type: 'GET_GENERAL_SETTINGS',
		payload: data
	}
}
export function getGeneralSettings() {
	return (dispatch) => {
		dispatch({ type: 'GET_GENERAL_SETTINGS', payload: [], loading: true });
		getRequest(`setting/general`, generalAction, dispatch)
	}

}
//END OF GENERAL SETTINGS

//COMMENTS
export const comments = (data) => {
	return {
		type: 'GET_COMMENTS',
		payload: data
	}
}
export function getComments() {
	return (dispatch) => {
		dispatch({ type: 'GET_COMMENTS', payload: [], loading: true });
		getRequest(`comments`, comments, dispatch)
	}
}
//END OF COMMENTS

//TYPES
export const types = (data) => {
	return {
		type: 'GET_TYPES',
		payload: data
	}
}
export function getTypes() {
	return (dispatch) => {
		dispatch({ type: 'GET_TYPES', payload: [], loading: true });
		getRequest(`ticket-types`, types, dispatch)
	}
}
//END OF TYPES

// Priorities
export const priorities = (data) => {
	return {
		type: 'GET_PRIORITIES',
		payload: data
	}
}
export function getPriorities() {
	return (dispatch) => {
		dispatch({ type: 'GET_PRIORITIES', payload: [], loading: true });
		getRequest(`ticket-priorities`, priorities, dispatch)
	}
}
// End of Priorities

//Tag
export const tags = (data) => {
	return {
		type: 'GET_TAGS',
		payload: data
	}
}
export function getTags() {
	return (dispatch) => {
		dispatch({ type: 'GET_TAGS', payload: [], loading: true });
		getRequest(`tags`, tags, dispatch)
	}
}
//End of Tag

//Emails
export const emails = (data) => {
	return {
		type: 'GET_EMAIL',
		payload: data
	}
}
export function fetchEmails() {
	return (dispatch) => {
		dispatch({ type: 'GET_EMAIL', payload: [], loading: true });
		getRequest('emails', emails, dispatch)
	}
}
//End of Emails

//Apps
export const apps = (data) => {
	return {
		type: 'GET_APPS',
		payload: data
	}
}
export function getApps() {
	return (dispatch) => {
		dispatch({ type: 'GET_APPS', payload: [], loading: true });
		getRequest('company-details', apps, dispatch)
	}
}
//End of Apps

//DASHBOARD & TICKETS SUMMARY
export const dashboard = (data) => {
	return {
		type: 'GET_DASHBOARD',
		payload: data
	}
}
export function fetchDashboard() {
	return (dispatch) => {
		dispatch({ type: 'GET_DASHBOARD', payload: [], loading: true });
		getRequest('dashboard', dashboard, dispatch)
	}
}
export const ticketsCount = (data) => {
	return {
		type: 'GET_TICKETS_COUNT',
		payload: data
	}
}
export function fetchTicketsCount() {
	return (dispatch) => {
		dispatch({ type: 'GET_TICKETS_COUNT', payload: [], loading: true });
		getRequest('tickets-count', ticketsCount, dispatch)
	}
}
export const ticketsSummary = (data) => {
	return {
		type: 'GET_TICKETS_SUMMARY',
		payload: data
	}
}
export function fetchTicketsSummary() {
	return (dispatch) => {
		dispatch({ type: 'GET_TICKETS_SUMMARY', payload: [], loading: true });
		getRequest('tickets-summary', ticketsSummary, dispatch)
	}
}
export const setSeenTour = (data) => {
	return {
		type: 'SET_SEEN_TOUR',
		payload: data
	}
}
export function seenTour() {
	return (dispatch) => {
		postRequest('seen-tour', setSeenTour, {}, dispatch)
	}
}

//REPORTS GENERATE
export const reports = (data) => {
	return {
		type: 'GENERATE_REPORTS',
		payload: data
	}
}
export const reportsClear = () => {
	return {
		type: 'GENERATE_REPORTS_CLEAR',
	}
}
export function generateReports(type, separatedUsers, startDate, endDate) {
	return (dispatch) => {
		let url = `report-tickets?type=${type}&users=${separatedUsers}&start=${startDate}&endDate=${endDate}`
		dispatch({ type: 'GENERATE_REPORTS', payload: [], loading: true });
		getRequest(url, reports, dispatch)
	}
}

//GET CLARITASK TASKS
export const getTasks = (payload) => {
	return {
		type: 'GET_TASKS',
		payload
	}
}
export function fetchTasks(id) {
	return (dispatch) => {
		dispatch({ type: 'GET_TASKS', payload: [], loading: true });
		getRequest(`get-ticket-task/${id}`, getTasks, dispatch)
	}
}
export const deleteTask = (id) => {
	return {
		type: 'DELETE_TASK',
		payload: id
	}
}

//Filter Reports Dates
export const getDates = (dates) => {
	return {
		type: "GET_DATE",
		payload: dates
	}
}

//Overview Report
export const getOverview = (data) => {
	return {
		type: 'GET_OVERVIEW',
		payload: data
	}
}
export function fetchOverview(startTime, agents, statuses, priorities, tags, endTime) {
	return (dispatch) => {
		let url = `report-overview?from=${startTime}&agents=${agents}&statuses=${statuses}&priorities=${priorities}&tags=${tags}&to=${endTime}`
		dispatch({ type: 'GET_OVERVIEW', payload: [], loading: true });
		getRequest(url, getOverview, dispatch)
	}
}

//Activity Report
export const getActivity = (data) => {
	return {
		type: 'GET_ACTIVITY',
		payload: data
	}
}
export function fetchActivity(startTime, agents, statuses, priorities, tags, endTime) {
	return (dispatch) => {
		let url = `report-activity?from=${startTime}&agents=${agents}&statuses=${statuses}&priorities=${priorities}&tags=${tags}&to=${endTime}`
		dispatch({ type: 'GET_ACTIVITY', payload: [], loading: true });
		getRequest(url, getActivity, dispatch)
	}
}

//Ticket Report
export const getTicketsReport = (data) => {
	return {
		type: 'GET_TICKETS_REPORT',
		payload: data
	}
}
export function fetchTicketsReport(startTime, agents, statuses, priorities, tags, endTime) {
	return (dispatch) => {
		let url = `report-tickets?from=${startTime}&agents=${agents}&statuses=${statuses}&priorities=${priorities}&tags=${tags}&to=${endTime}`
		dispatch({ type: 'GET_TICKETS_REPORT', payload: [], loading: true });
		getRequest(url, getTicketsReport, dispatch)
	}
}

//Team Report
export const getTeamReport = (data) => {
	return {
		type: 'GET_TEAM_REPORT',
		payload: data
	}
}
export function fetchTeamReport(startTime, agents, statuses, priorities, tags, endTime) {
	return (dispatch) => {
		let url = `report-team?from=${startTime}&agents=${agents}&statuses=${statuses}&priorities=${priorities}&tags=${tags}&to=${endTime}`
		dispatch({ type: 'GET_TEAM_REPORT', payload: [], loading: true });
		getRequest(url, getTeamReport, dispatch)
	}
}

//Time Report
export const getTimeReport = (data) => {
	return {
		type: 'GET_TIME_REPORT',
		payload: data
	}
}
export function fetchTimeReport(startTime, agents, statuses, priorities, tags, endTime) {
	return (dispatch) => {
		let url = `report-time?from=${startTime}&agents=${agents}&statuses=${statuses}&priorities=${priorities}&tags=${tags}&to=${endTime}`
		dispatch({ type: 'GET_TIME_REPORT', payload: [], loading: true });
		getRequest(url, getTimeReport, dispatch)
	}
}

//LEFT FILTER FUNCTIONS
//Filter Reports STATUSES
export const getReportStatuses = (statuses) => {
	return {
		type: "GET_REPORT_STATUS",
		payload: statuses
	}
}

//Filter Reports Agents
export const getReportAgents = (agents) => {
	return {
		type: "GET_REPORT_AGENTS",
		payload: agents
	}
}

//Filter Reports Priorities
export const getReportPriorities = (priorities) => {
	return {
		type: "GET_REPORT_PRIORITIES",
		payload: priorities
	}
}

//Filter Reports Tags
export const getReportTags = (tags) => {
	return {
		type: "GET_REPORT_TAGS",
		payload: tags
	}
}

//Get Subscription
export const getSubscription = (data) => {
	return {
		type: 'GET_SUBSCRIPTION',
		payload: data
	}
}
export function fetchSubscription() {
	return (dispatch) => {
		dispatch({ type: 'GET_SUBSCRIPTION', payload: [], loading: true });
		getRequest('subscribe', getSubscription, dispatch)
	}
}

//GET IMAGES FROM STORAGE
export const getImages = (data) => {
	return {
		type: 'GET_IMAGES',
		payload: data
	}
}
export function fetchImages() {
	return (dispatch) => {
		dispatch({ type: 'GET_IMAGES', payload: [], loading: true });
		getRequest('media-library/files', getImages, dispatch)
	}
}

export const getImagesStorage = (data) => {
	return {
		type: 'GET_IMAGES_STORAGE',
		payload: data
	}
}
export function fetchImagesStorage() {
	return (dispatch) => {
		dispatch({ type: 'GET_IMAGES_STORAGE', payload: [], loading: true });
		getRequest('media-library/storage-size', getImagesStorage, dispatch)
	}
}

// BLOG
export const getBlogs = (data) => {
	return {
		type: 'GET_BLOGS',
		payload: data
	}
}
export function fetchBlogs(page, limit, category) {
	return (dispatch) => {
		dispatch({ type: 'GET_BLOGS', payload: [], loading: true });
		postRequest('get-articles', getBlogs, { page, limit, category }, dispatch)
	}
}

//Blog Details
export const getBlog = (data) => {
	return {
		type: 'GET_BLOG',
		payload: data
	}
}
export function fetchBlog(article_id) {
	return (dispatch) => {
		dispatch({ type: 'GET_BLOG', payload: [], loading: true });
		postRequest('get-article', getBlog, { article_id }, dispatch)
	}
}

//Blog Categories
export const getBlogCategories = (data) => {
	return {
		type: 'GET_BLOG_CATEGORIES',
		payload: data
	}
}
export function fetchBlogCategories(article_id) {
	return (dispatch) => {
		dispatch({ type: 'GET_BLOG_CATEGORIES', payload: [], loading: true });
		postRequest('get-categories', getBlogCategories, { article_id }, dispatch)
	}
}

// Add workspace to user
export const addWorkspaceToUser = (workspace) => {
	return {
		type: 'ADD_WORKSPACE_TO_USER',
		payload: workspace,
	};
};


// start line updatesTaskReducer
export const changedColumns = (payload,changedBy) => {
    return {
        type: 'CHANGED_COLUMNS',
        payload,
        changedBy
    }
}
export const resetChangedColumns = () => {
    return {
        type: 'RESET_CHANGED_COLUMNS'
    }
}
export const removeChangedColumn = (payload) => {
    return {
        type: 'REMOVE_CHANGED_COLUMN',
        payload
    }
}
// end line updatesTaskReducer
