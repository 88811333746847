
import React from "react";
import {Line} from "react-chartjs-2";
import {Row, Col} from "react-bootstrap";
import interacted from "../../../../../css/img/interacted.svg";
import reportTickets from "../../../../../css/img/raport-new.svg"


const TimeLogged = ({report, dates}) => {
    let times = report && report.timeLoggedGraph
    let time =  times && Object.values(times)

    const data = {
        labels: dates,
        datasets: [{
            label: 'Time logged',
            data: time,
            fill: false,
            backgroundColor: '#fff',
            borderColor: '#27AE60',
            borderWidth: 2,
            hoverBackgroundColor: '#27AE60',
            pointBackgroundColor: '#27AE60',
            pointRadius: 5,
            pointHoverRadius: 5
        }]
    }
    const options = {
        elements: {
            line: {
                tension: 0.1 // disables bezier curves
            }
        },
        scales: {
            yAxes: [
                {
                    type: 'linear',
                    position: 'left',
                    display: true,
                    gridLines: {display: true},
                    ticks: {
                        beginAtZero: true,
                        stepSize: 1000,
                        userCallback: function(label, index, labels) {
                            // when the floored value is the same as the value we have a whole number
                            if (Math.floor(label) === label) {
                                return label;
                            }

                        },
                    }
                },
                {
                    type: 'linear',
                    position: 'left',
                    display: false,
                    gridLines: {display: false},
                    ticks: {
                        precision: 0
                    }
                },
                {
                    type: 'linear',
                    position: 'left',
                    display: false,
                    gridLines: {display: false},
                },
            ],
            xAxes: [
                {
                    gridLines: {
                        display: false,
                    },
                }
            ],
        },
        legend: {
            position: "top",
            align: "center",
            labels: {
                padding: 25,
                usePointStyle: true,
                pointStyle: 'circle',
                boxWidth: 8,
                fontColor: '#25313C',
                fontSize: 12,
                fontStyle: "normal",
                fontWeight: "normal",
            }
        },
        tooltips: {
            mode: 'index',
            intersect: false,
            usePointStyle: true,
            backgroundColor: '#fff',
            displayColors: true,
            boxShadow: "0px 10.2133px 30.6398px -7.65994px rgba(15, 34, 67, 0.16), 0px 1.27666px 3.82997px rgba(15, 34, 67, 0.12), 0px 0px 1.27666px rgba(15, 34, 67, 0.16);",
            callbacks: {
                labelTextColor: function(tooltipItem, chart){
                    let dataset = chart.config.data.datasets[tooltipItem.datasetIndex];
                    return {
                        color : dataset.backgroundColor
                    }
                },
            }
        },
        hover: {
            mode: 'index',
            intersect: false
        }
    };
    return(
        <div className="report-chart-holder">
            <Line
                data={data}
                options={options}
                width={100}
                height={30}
            />
            <Row className="mt-4">
                <Col xs={12}  sm={4}>
                    <div className="reports-ticket-activity mb-3">
                        <div className="reports-ticket-activity-header">
                            <img src={reportTickets} alt="ticket"/>
                            <span>{report && report.totalTimeLogged ? report.totalTimeLogged : "No time Logged yet" }</span>
                        </div>
                        <h6>Total time logged</h6>
                    </div>
                </Col>
                <Col xs={12}  sm={4}>
                    <div className="reports-ticket-activity mb-3">
                        <div className="reports-ticket-activity-header">
                            <img src={interacted} alt="ticket"/>
                            <span>{report && report.avgTimeLoggedPerDay ? report.avgTimeLoggedPerDay : "No time Logged yet" }</span>
                        </div>
                        <h6>Average time logged per day</h6>
                    </div>
                </Col>
                <Col xs={12}  sm={4}>
                    <div className="reports-ticket-activity mb-3">
                        <div className="reports-ticket-activity-header">
                            <img src={reportTickets} alt="ticket"/>
                            <span>{report && report.totalTickets ? report.totalTickets : "No time Logged yet" }</span>
                        </div>
                        <h6>Total tickets</h6>
                    </div>
                </Col>
                {/*<Col xs={12} md={6} xl={3}>*/}
                {/*    <div className="reports-ticket-activity mb-3">*/}
                {/*        <div className="reports-ticket-activity-header">*/}
                {/*            <img src={customersImg} alt="ticket"/>*/}
                {/*            <span>6h 26m 12s</span>*/}
                {/*        </div>*/}
                {/*        <h6>Total agents</h6>*/}
                {/*    </div>*/}
                {/*</Col>*/}
            </Row>
        </div>
    )
}
export default TimeLogged
