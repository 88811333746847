import React, {useEffect} from "react"
import {Row, Col} from "react-bootstrap"
import OverviewChart from "./OverviewChart";
import TeamPerformance from "./TeamPerfomance";
import CustomerHappiness from "./CustomerHappiness";
import TicketChanel from "./TicketByChanel";
import TicketByStatus from "./TicketsByStatus";
import FilterComponent from "../Filter/FilterComponent";
import {useSelector} from "react-redux";
import MainReport from "../MainReport";
import {fetchOverview} from "../../../../redux/actions";


const Overview = () => {
    const dates = useSelector(state => state.getDatesReducer.dates)
    const report = useSelector(state => state.OverviewReducer.data)

    return(
        <MainReport title="Reports Overview" reqFrom="overview">
            <Row>
                <Col xs={12} sm={9}>
                    <Row>
                        <Col xs={12}>
                            <OverviewChart dates={dates} report={report}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}  sm={6}>
                            <TeamPerformance report={report}/>
                        </Col>
                        <Col xs={12}  sm={6}>
                            <TicketByStatus dates={dates} report={report}/>
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} sm={3}>
                    <FilterComponent url={fetchOverview}/>
                </Col>
            </Row>
        </MainReport>
    )
}
export default Overview
