import React, {useState} from "react"
import {Line} from "react-chartjs-2"
import {Row, Col} from "react-bootstrap"
import {IoMdArrowDropup} from "react-icons/io";

const OverviewChart = ({dates, report}) => {
    var ticketsValue = report  && report?.tickets && Object.values(report?.tickets)
    ticketsValue ??= [];

    var repliesValue = report  && report?.replies && Object.values(report?.replies)
    repliesValue ??= [];

    var firstResponse = report  && report?.firstResponseTime && Object.values(report?.firstResponseTime)
    firstResponse ??= [];

    const data = {
        labels: dates,
        datasets: [
            {
                label: 'Tickets',
                data: ticketsValue,
                fill: false,
                backgroundColor: '#fff',
                borderColor: '#27AE60',
                borderWidth: 2,
                hoverBackgroundColor: '#27AE60',
                pointBackgroundColor: "#27AE60",
            },
            {
                label: 'Replies',
                data: repliesValue,
                fill: false,
                backgroundColor: '#fff',
                borderColor: '#2D9CDB',
                borderWidth: 2,
                hoverBackgroundColor: '#2D9CDB',
                pointBackgroundColor: "#2D9CDB",
            },
            {
                label: 'First response time',
                data: firstResponse,
                fill: false,
                backgroundColor: '#fff',
                borderColor: '#F2994A',
                borderWidth: 2,
                hoverBackgroundColor: '#F2994A',
                pointBackgroundColor: "#F2994A",
            },
        ],
    };
    const options = {
        elements: {
            line: {
                tension: 0.1 // disables bezier curves
            }
        },
        scales: {
            yAxes: [
                {
                    type: 'linear',
                    position: 'left',
                    display: true,
                    gridLines: {display: true},
                    ticks: {
                        beginAtZero: true,
                        stepSize: 10,
                        userCallback: function(label, index, labels) {
                            // when the floored value is the same as the value we have a whole number
                            if (Math.floor(label) === label) {
                                return label;
                            }

                        },
                    }
                },
                {
                    type: 'linear',
                    position: 'left',
                    display: false,
                    gridLines: {display: false},
                    ticks: {
                        precision: 0
                    }
                },
                {
                    type: 'linear',
                    position: 'left',
                    display: false,
                    gridLines: {display: false},
                },
            ],
            xAxes: [
                {
                    gridLines: {
                        display: false,
                    },
                }
            ],
        },
        legend: {
            position: "top",
            align: "center",
            labels: {
                padding: 25,
                usePointStyle: true,
                pointStyle: 'circle',
                boxWidth: 8,
                fontColor: '#25313C',
                fontSize: 14,
                fontStyle: "normal",
                fontWeight: "normal",
            }
        },
        tooltips: {
            mode: 'index',
            intersect: false,
            usePointStyle: true,
            backgroundColor: '#fff',
            displayColors: true,
            boxShadow: "0px 10.2133px 30.6398px -7.65994px rgba(15, 34, 67, 0.16), 0px 1.27666px 3.82997px rgba(15, 34, 67, 0.12), 0px 0px 1.27666px rgba(15, 34, 67, 0.16);",
            callbacks: {
                labelTextColor: function(tooltipItem, chart){
                    let dataset = chart.config.data.datasets[tooltipItem.datasetIndex];
                    return {
                        color : dataset.backgroundColor
                    }
                },
            }
        },
        hover: {
            mode: 'index',
            intersect: false
        }
    };


    return(
        <div className="report-chart-holder">
            <h3 className="report-overview-h3">First Response Time and Volume</h3>
            <Line
                data={data}
                options={options}
                width={100}
                height={30}
            />
            {/*<Row style={{marginTop: '30px'}}>*/}
            {/*    <Col sm={12} md={6} lg={4} xl={3}>*/}
            {/*        <div className="chart-widget-report mb-3">*/}
            {/*            <span>9</span>*/}
            {/*            <h4>Customers Helped</h4>*/}
            {/*            <div>*/}
            {/*                <IoMdArrowDropup*/}
            {/*                    style={{*/}
            {/*                        fontSize: '22px',*/}
            {/*                        color: helped >= 50 ? '#16A000' : '#FC0000',*/}
            {/*                        transform: helped >= 50 ? 'unset' : 'rotate(180deg)'*/}
            {/*                    }}*/}
            {/*                />*/}
            {/*                {helped}%*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </Col>*/}
            {/*    <Col sm={12} md={6} lg={4} xl={3}>*/}
            {/*        <div className="chart-widget-report mb-3">*/}
            {/*            <span>{report && report.data.solvedTickets}</span>*/}
            {/*            <h4>Tickets Solved</h4>*/}
            {/*            <div>*/}
            {/*                <IoMdArrowDropup*/}
            {/*                    style={{*/}
            {/*                        fontSize: '22px',*/}
            {/*                        color: solved >= 50 ? '#16A000' : '#FC0000',*/}
            {/*                        transform: solved >= 50 ? 'unset' : 'rotate(180deg)'*/}
            {/*                    }}*/}
            {/*                />*/}
            {/*                {solved}%*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </Col>*/}
            {/*    <Col sm={12} md={6} lg={4} xl={3}>*/}
            {/*        <div className="chart-widget-report mb-3">*/}
            {/*            <span>{report && report.data.totalReplies}</span>*/}
            {/*            <h4>Total Replies</h4>*/}
            {/*            <div>*/}
            {/*                <IoMdArrowDropup*/}
            {/*                    style={{*/}
            {/*                        fontSize: '22px',*/}
            {/*                        color: replied >= 50 ? '#16A000' : '#FC0000',*/}
            {/*                        transform: replied >= 50 ? 'unset' : 'rotate(180deg)'*/}
            {/*                    }}*/}
            {/*                />*/}
            {/*                {replied}%*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </Col>*/}
            {/*    <Col sm={12} md={6} lg={4} xl={3}>*/}
            {/*        <div className="chart-widget-report mb-3">*/}
            {/*            <span>{report && report.data.avgFirstResponseTime}</span>*/}
            {/*            <h4>Avg. first Response time</h4>*/}
            {/*            <div>*/}
            {/*                <IoMdArrowDropup*/}
            {/*                    style={{*/}
            {/*                        fontSize: '22px',*/}
            {/*                        color: response >= 50 ? '#16A000' : '#FC0000',*/}
            {/*                        transform: response >= 50 ? 'unset' : 'rotate(180deg)'*/}
            {/*                    }}*/}
            {/*                />*/}
            {/*                {response}%*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </Col>*/}
            {/*</Row>*/}
        </div>
    )
}
export default OverviewChart
