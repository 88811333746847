import React from "react"


const ExpensiveTickets = ({report}) => {
    return(
        <div className="report-chart-holder mr-0">
            <h4 className="report-chart-titles">Most Expensive Tickets</h4>
            <div className="table-responsive">
                <table className="table performance-table table-striped">
                    <thead>
                    <tr>
                        <th>Ticket</th>
                        <th>Thread Count</th>
                        <th>Agents Involved</th>
                    </tr>
                    </thead>
                    <tbody>
                    {report && report?.data?.mostExpensiveTickets ? report.data.mostExpensiveTickets.map(ticket => (
                        <tr key={ticket.id}>
                            <td className="add-three-dots-p">{ticket.title}</td>
                            <td>{ticket.thread_count}</td>
                            <td>{ticket.user_count}</td>
                        </tr>
                    )) : <tr>
                            <td></td>
                            <td>No data</td>
                            <td></td>
                        </tr>}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
export default ExpensiveTickets
