import React, { useEffect, useState, useMemo } from "react";
import { fetchTicketsSummary } from "../../redux/actions";
import { IoIosArrowForward } from "react-icons/io";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

const SidebarMenu = () => {
	const [active, setActive] = useState('dashboard')
	const [activeSub, setActiveSub] = useState()
	const [opened, setOpened] = useState('')

	const location = useLocation()

	useEffect(() => {
		const path = location.pathname.split('/')[1] || 'dashboard';
		const childPath = location.pathname;
		const subNavKeys = menuItems.filter(item => item.subNav.length > 0).map(item => item.key)
		setActive(path);
		if (subNavKeys.includes(path)) {
			setOpened(path);
			setActiveSub(childPath.substring(1).replace('/', '_'));
		}
	}, [location.pathname]);

	const toggleClass = (key, children) => {
		setOpened(opened === key || children.length === 0 ? '' : key)
	}
	const toggleClassSub = (e, key) => {
		e.stopPropagation()
		setActiveSub(key)
	}

	const ticketsSummary = useSelector(state => state.dashboardReducer.ticketsSummary);
	const user = useSelector(state => state.userReducer.user);

	const menuItems = useMemo(() => [
		{
			id: 0,
			name: 'Dashboard',
			path: '/dashboard',
			subNav: [],
			key: 'dashboard',
			role: 'agent',
		}, {
			id: 1,
			name: 'Tickets',
			path: '/tickets',
			key: 'tickets',
			role: 'agent',
			subNav: [
                {
                    id: -1,
                    name: 'All Tickets',
                    path: '/tickets',
                    key: 'tickets_all',
                    badge: ticketsSummary && ticketsSummary['AllTickets'] ? ticketsSummary['AllTickets'] : 0,
                },
				{
					id: 0,
					name: 'Open',
					path: '/tickets/open',
					key: 'tickets_open',
					badge: ticketsSummary && ticketsSummary['Open'] ? ticketsSummary['Open'] : 0,

				},
				{
					id: 1,
					name: 'Active',
					path: '/tickets/active',
					key: 'tickets_active',
					badge: ticketsSummary && ticketsSummary['Active'] ? ticketsSummary['Active'] : 0,

				},
				{
					id: 2,
					name: 'Unassigned',
					path: '/tickets/unassigned',
					key: 'tickets_unassigned',
					badge: ticketsSummary && ticketsSummary['Unassigned'] ? ticketsSummary['Unassigned'] : 0,
				},
				{
					id: 3,
					name: 'My Tickets',
					path: '/tickets/my',
					key: 'tickets_my',
					badge: ticketsSummary && ticketsSummary['MyTickets'] ? ticketsSummary['MyTickets'] : 0,
				},
				{
					id: 4,
					name: 'Pending',
					path: '/tickets/pending',
					key: 'tickets_pending',
					badge: ticketsSummary && ticketsSummary['Pending'] ? ticketsSummary['Pending'] : 0,
				},
				{
					id: 5,
					name: 'Assigned',
					path: '/tickets/assigned',
					key: 'tickets_assigned',
				},
				{
					id: 6,
					name: 'Closed',
					path: '/tickets/closed',
					key: 'tickets_closed',
				},
				{
					id: 7,
					name: 'Spam',
					path: '/tickets/spam',
					key: 'tickets_spam',
				},
				{
					id: 8,
					name: 'Deleted',
					path: '/tickets/deleted',
					key: 'tickets_deleted',
				}
			]
		}, {
			id: 2,
			name: 'Users',
			path: '/users',
			subNav: [],
			key: 'users',
			role: 'admin',
		},
		{
			id: 3,
			name: 'Departments',
			path: '/departments',
			subNav: [],
			key: 'departments',
			role: 'admin',
		},
		{
			id: 4,
			name: 'Incoming Emails',
			path: '/emails',
			subNav: [],
			key: 'emails',
			role: 'admin',
		},
		{
			id: 5,
			name: 'Reports',
			path: '/reports/overview',
			key: 'reports',
			role: 'agent',
			subNav: [
				{
					id: 0,
					name: 'Overview',
					path: '/reports/overview',
					key: 'reports_overview',
				},
				{
					id: 1,
					name: 'Activity',
					path: '/reports/activity',
					key: 'reports_activity',
				},
				{
					id: 2,
					name: 'Tickets',
					path: "/reports/tickets",
					key: "reports_tickets",
				},
				{
					id: 3,
					name: 'Team',
					path: "/reports/team",
					key: "reports_team",
				},
				{
					id: 4,
					name: 'Time',
					path: "/reports/time",
					key: "reports_time",

				}
			]
		},
		{
			id: 6,
			name: 'Settings',
			path: '/settings',
			key: 'settings',
			role: 'admin',
			subNav: [
				{
					id: 0,
					name: 'Types',
					path: '/settings/type',
					key: 'settings_type',
				},
				{
					id: 1,
					name: 'Priorities',
					path: '/settings/priority',
					key: 'settings_priority',
				},
				{
					id: 2,
					name: 'Tags',
					path: '/settings/tags',
					key: 'settings_tags',
				},
				{
					id: 3,
					name: 'Apps',
					path: "/settings/connect_cc",
					key: "settings_connect_cc",
				}

			]
		},
		{
			id: 7,
			name: 'Subscription',
			path: '/subscription',
			key: 'subscription',
			role: 'admin',
			subNav: [
				{
					id: 0,
					name: 'Current Plan',
					path: '/subscription/plan',
					key: 'subscription_plan',
				},
				{
					id: 1,
					name: 'Payment Method',
					path: '/subscription/payment',
					key: 'subscription_payment',
				},
				{
					id: 2,
					name: 'Invoice History',
					path: '/subscription/invoice',
					key: 'subscription_invoice',
				},
			]
		},
        {
            id: 8,
            name: 'Developer Options',
            path: '/developer',
            key: 'developer',
            role: 'admin',
            subNav: [],
        }
	], [ticketsSummary]);

	return (
		<ul className="nav flex-column parent-ul-container">
			{menuItems.map(nav => {
				if (nav.role == 'agent' && user.isAgent || nav.role == 'admin' && user.isAdmin) {
					return (
						<li key={nav.id} className={`nav-item parent-li-item ${nav.key == active ? 'active' : ''} ${nav.key === opened ? 'show-children' : ''}`}
							onClick={() => toggleClass(nav.key, nav.subNav)}>
							<Link className="nav-link" to={nav.subNav.length > 0 ? "#" : nav.path}>
								<span className='new-icon-sidebar' id={`${nav.name.toLowerCase().replace(' ', '-')}-icon`}></span>
								{nav.name}
								{nav.subNav.length > 0 ? <IoIosArrowForward /> : ''}
							</Link>
							{nav.subNav.length > 0 ?
								<ul className="nav flex-column child-ul-container">
									{nav.subNav.map(sub => (
										<li key={sub.id} className={`nav-item child-li-item ${sub.key === activeSub ? 'active' : ''}`} onClick={(e) => toggleClassSub(e, sub.key)}>
											<Link className="nav-link" to={sub.path}>{sub.name}</Link>
											{sub.badge ? <span className={"badge" + (sub.badge > 0 ? " danger" : "")}>{sub.badge}</span> : ''}
										</li>
									))}
								</ul>
								: ''}
						</li>
					)
				} else {
					return null;
				}
			})}
		</ul>
	);
};

export default SidebarMenu;
