import React, {useEffect} from "react"
import {IoChevronDown} from "react-icons/io5";
import img from "../../../../../css/img/avatar.png";
import properties from "../../../../../css/img/properties.svg";
import {useDispatch, useSelector} from "react-redux";
import Agents from "./Agents";
import Statuses from "./Statuses";
import Priorities from "./Priorities";
import Tags from "./Tags";
import moment from "moment";
import { MdOutlineSupportAgent, MdOutlineLowPriority } from "react-icons/md";
import { BsClipboard2Check } from "react-icons/bs";
import { Accordion } from "react-bootstrap";
// import { MdOutlineLowPriority } from "react-icons/md";
import { LuTags } from "react-icons/lu";

const style = {
    h5: {
        fontSize: 16,
        marginBottom: 0
    }
}

const FilterComponent = ({url}) => {
    const dispatch = useDispatch()
    const dates = useSelector(state => state.getDatesReducer.date)
    const agents = useSelector(state => state.AgentsFilter.data)
    const statuses = useSelector(state => state.StatusFilter.data)
    const priorities = useSelector(state => state.PrioritiesFilter.data)
    const tags = useSelector(state => state.TagsFilter.data)
    let startDate = dates && moment(dates[0]).format("YYYY-MM-DD")
    let endDate = dates && moment(dates[1]).format("YYYY-MM-DD")

    useEffect(() => {
        dispatch(url(startDate, agents, statuses, priorities, tags, endDate))
    },[agents, statuses, priorities, tags, dates])
    return(
            <>
               <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            <MdOutlineSupportAgent size={20} className="me-2" />
                            <h5 style={style.h5}>Agents</h5>
                        </Accordion.Header>
                        <Accordion.Body>
                            <Agents/>
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="1">
                        <Accordion.Header>
                            <BsClipboard2Check size={20} className="me-2" />
                            <h5 style={style.h5}>Statuses</h5>
                        </Accordion.Header>
                        <Accordion.Body>
                            <Statuses/>
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="2">
                        <Accordion.Header>
                            <MdOutlineLowPriority size={20} className="me-2" />
                            <h5 style={style.h5}>Priorities</h5>
                        </Accordion.Header>
                        <Accordion.Body>
                            <Priorities/>
                        </Accordion.Body>
                    </Accordion.Item>
                    
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>
                            <LuTags size={20} className="me-2" />
                            <h5 style={style.h5}>Tags</h5>
                        </Accordion.Header>
                        <Accordion.Body>
                            <Tags/>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </>
    )
}
export default FilterComponent
