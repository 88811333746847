import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux";
import {fetchStatuses, getReportStatuses} from "../../../../redux/actions";
import {IoSearchOutline} from "react-icons/io5";

const Statuses = ({url}) => {
    const dispatch = useDispatch()
    const [values, setValue] = useState([])
    const [query, setQuery] = useState('')

    useEffect(() => {
        dispatch(fetchStatuses())
    },[])

    useEffect(() => {
        dispatch(getReportStatuses(values.toString()))
    },[values])

    const statuses = useSelector(state => state.StatusFilter.statuses)


    const stats = statuses && statuses.map(stat => {
        return stat.id
    })

    const handleCheckAll = () => {
        if(values.length < priorities.length){
            setValue(stats)
        } else {
            setValue([])  
        }
    }

    const handleChange = (id) => {
        if(values.includes(id)){
            setValue(values.filter(value => value != id))
        } else {
            setValue([...values, id])
        }
    };

    return(
        <div>
            <div className="position-relative search-input-style" style={{marginBottom: "15px"}}>
                <IoSearchOutline/>
                <input type="search" placeholder="Search Status" onKeyUp={(e) => setQuery(e.target.value)}/>
            </div>
            {
                statuses &&
                <>
                   <div className="custom-checkbox-wrapper">
                        <div className="custom-checkbox-item">
                            <input type="checkbox" id="check-all" onChange={handleCheckAll} checked={values.length == priorities.length}/>
                            <label for="check-all">
                                <span>{values.length == priorities.length ? "Deselect All" : "Select All"}</span>
                            </label>
                        </div>
                        {statuses.map(status => {
                            return(
                                <div className="custom-checkbox-item">
                                    <input type="checkbox" 
                                        id={status.id} 
                                        value={status.id} 
                                        checked={values.includes(status.id)}
                                        onChange={() => handleChange(status.id)}
                                    />
                                    <label for={status.id}>
                                        <span>{status.name}</span>
                                    </label>
                                </div>
                            )
                        })}
                   </div>
                </>
            }
        </div>
    )
}
export default Statuses
