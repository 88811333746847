import React, {useEffect, useState} from "react"
import {Col, Row} from "react-bootstrap";
import {useDispatch} from "react-redux";
import MainContainer from "../../MainContainer";
import {fetchTeamReport, fetchTimeReport, getDates} from "../../../redux/actions";
import moment from "moment";
import {fetchOverview, fetchActivity, fetchTicketsReport} from "../../../redux/actions";
import DateRange from "../../../helpers/DateRange";


const MainReport = (props) => {
    const dispatch = useDispatch()

    const handleChange = (e) => {
        dispatch(getDates(e))
    }

    useEffect(() => {
        dispatch(getDates([new Date(Date.now() - 7 * 24 * 60 * 60 * 1000), new Date]))
    }, [])

    return(
       <MainContainer>
           <div className="section-container generate-report-container pb-4">
               <Row>
                   <Col sm={12} xl={9}>
                       <Row className="align-items-center justify-content-between" style={{padding: "20px 30px 0px 30px"}}>
                           <Col sm={12} md={6} lg={8}>
                               <div className="report-header">
                                   <h4>{props.title}</h4>
                               </div>
                           </Col>
                           <Col sm={12} md={6} lg={4}>
                               <div className="report-header">
                                    <DateRange handleDateChange={handleChange}/>
                               </div>
                           </Col>
                       </Row>
                   </Col>
                   <Col sm={12} xl={3}>
                       <div className="report-header" style={{margin: "20px 30px 30px 0px"}}>
                           <h4 style={{paddingBottom: "1px solid #EFEFEF"}}>Filter</h4>
                       </div>
                   </Col>
               </Row>
               {props.children}
           </div>
       </MainContainer>
    )
}
export default MainReport
