import React from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';

const CustomProgressBar = ({ now, label, color }) => {
  return (
    <div>
      <ProgressBar now={now} label={label} />
      <style>{`
        .progress-bar {
          background-color: ${color}; 
          border-radius: 10px; 
          height: 17px; 
        }
      `}</style>
    </div>
  );
};

export default CustomProgressBar;
