import React, {useEffect} from "react"
import {Line} from "react-chartjs-2";


const StatusActivity = ({dates, report}) => {
    let statuses = report && (report?.data?.ticketByStatusActivity || {});
    let stats = statuses && Object.keys(statuses).map(status => {
        let name = status.split('-')
        return(
            {
                label: name[0],
                data: Object.values(statuses[status]),
                fill: false,
                backgroundColor: '#fff',
                borderColor:  name[1],
                borderWidth: 2,
                hoverBackgroundColor:   name[1],
                pointBackgroundColor:  name[1],
            }
        )
    })

    const data = {
        labels: dates,
        datasets: stats,
    };
    const options = {
        elements: {
            line: {
                tension: 0.1 // disables bezier curves
            }
        },
        scales: {
            yAxes: [
                {
                    type: 'linear',
                    position: 'left',
                    display: true,
                    gridLines: {display: true},
                    ticks: {
                        beginAtZero: true,
                        stepSize: 10,
                        userCallback: function(label, index, labels) {
                            // when the floored value is the same as the value we have a whole number
                            if (Math.floor(label) === label) {
                                return label;
                            }

                        },
                    }
                },
                {
                    type: 'linear',
                    position: 'left',
                    display: false,
                    gridLines: {display: false},
                    ticks: {
                        precision: 0
                    }
                },
                {
                    type: 'linear',
                    position: 'left',
                    display: false,
                    gridLines: {display: false},
                },
            ],
            xAxes: [
                {
                    gridLines: {
                        display: false,
                    },
                }
            ],
        },
        legend: {
            position: "bottom",
            align: "center",
            labels: {
                padding: 25,
                usePointStyle: true,
                pointStyle: 'circle',
                boxWidth: 8,
                fontColor: '#25313C',
                fontSize: 12,
                fontStyle: "normal",
                fontWeight: "normal",
            }
        },
        tooltips: {
            mode: 'index',
            intersect: false,
            usePointStyle: true,
            backgroundColor: '#fff',
            displayColors: true,
            boxShadow: "0px 10.2133px 30.6398px -7.65994px rgba(15, 34, 67, 0.16), 0px 1.27666px 3.82997px rgba(15, 34, 67, 0.12), 0px 0px 1.27666px rgba(15, 34, 67, 0.16);",
            callbacks: {
                labelTextColor: function(tooltipItem, chart){
                    let dataset = chart.config.data.datasets[tooltipItem.datasetIndex];
                    return {
                        color : dataset.backgroundColor
                    }
                },
            }
        },
        hover: {
            mode: 'index',
            intersect: false
        }
    };
    return(
        <div className="report-chart-holder ml-0">
            <h3 className="report-overview-h3">Tickets by Status Activity</h3>
            <Line
                data={data}
                options={options}
                width={100}
                height={50}
            />
        </div>
    )
}
export default StatusActivity
