import React from "react"
import {Row, Col} from "react-bootstrap"
import {IoIosArrowRoundForward} from "react-icons/io";
import reportTickets from "../../../../../css/img/raport-new.svg"
import customersImg from "../../../../../css/img/customers.svg"
import interacted from "../../../../../css/img/interacted.svg"

const TicketActivity = ({report}) => {
    // const interaction = 13.89
    // const customers = 59.98
    // const newTickets = 45.63

    // console.log(report);

    return(
        <div className="report-chart-holder mr-0" style={{minHeight: "350px"}}>
            <h4 className="report-chart-titles" style={{marginBottom: "60px"}}>Ticket Activity</h4>
            <Row className="justify-content-between">
                <Col xs={12} xl={4}>
                    <div className="reports-ticket-activity">
                        <div className="reports-ticket-activity-header">
                            <img src={interacted} alt="ticket"/>
                            <span>{report && report?.data?.ticketsActivity ? report?.data?.ticketsActivity?.ticketsInteractedWith : 0}</span>
                        </div>
                        <h6>Total tickets intercated with</h6>
                        <div className="reports-ticket-activity-footer">
                            {/* <IoIosArrowRoundForward style={{color: '#72C472', transform:' rotate(-45deg)', fontSize: "20px"}}/> */}
                            {/* <span style={{color: '#72C472'}}>{interaction}%</span> */}
                        </div>
                    </div>
                </Col>
                <Col xs={12} xl={4}>
                    <div className="reports-ticket-activity">
                        <div className="reports-ticket-activity-header">
                            <img src={customersImg} alt="ticket"/>
                            <span>{report && report?.data?.ticketsActivity ? report?.data?.ticketsActivity?.customers : 0}</span>
                        </div>
                        <h6>Customers</h6>
                        <div className="reports-ticket-activity-footer">
                            {/* <IoIosArrowRoundForward style={{color: '#72C472', transform:' rotate(-45deg)', fontSize: "20px"}}/> */}
                            {/* <span style={{color: '#72C472'}}>{customers}%</span> */}
                        </div>
                    </div>
                </Col>
                <Col xs={12} xl={4}>
                    <div className="reports-ticket-activity">
                        <div className="reports-ticket-activity-header">
                            <img src={reportTickets} alt="ticket"/>
                            <span>{report && report?.data?.ticketsActivity ? report?.data?.ticketsActivity?.newTicketRecieved : 0}</span>
                        </div>
                        <h6>New Tickets Recieved</h6>
                        <div className="reports-ticket-activity-footer">
                            {/* <IoIosArrowRoundForward style={{color: '#fb0415', transform:' rotate(45deg)', fontSize: "20px"}}/> */}
                            {/* <span style={{color: '#fb0415'}}>{newTickets}%</span> */}
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}
export default TicketActivity

