import React from 'react'
import {Col, Row} from "react-bootstrap";
import Chart from "react-google-charts";
import {Line} from "react-chartjs-2";

const DashboardTrends = (props) => {
    let trend = props.trends.dailyTickets

    const data = (canvas) => {
        const ctx = canvas.getContext("2d")
        const gradient = ctx.createLinearGradient(0, 0, 0, 450);
        gradient.addColorStop(0, 'rgba(0, 122, 255, 0.33)');
        gradient.addColorStop(0.5, 'rgba(255, 255, 255, 0.4)');
        return{
            labels: ["Mon", "Tue","Wed", "Thu","Fri", "Sat", "Sun"],
            datasets: [
                {
                    label: "Weekly Trends",
                    backgroundColor: gradient,
                    hoverBackgroundColor: [
                        'rgba(255, 99, 132, 0.9)',
                        'rgba(75, 192, 192, 0.9)',
                    ],
                    borderWidth: 1,
                    borderColor: 'rgba(0, 122, 225, 1)',
                    pointBorderWidth: 0,
                    data: [trend?.monday, trend?.tuesday, trend?.wednesday, trend?.thursday, trend?.friday, trend?.saturday, trend?.sunday],
                },
            ],
        }
    }
    return(
        <>
            <Col  xl={8}  xs={12} className="pe-3 pe-xl-0 mb-3">
                <div className="bg-white after-left p-5 h-100" style={{borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px', minHeight: "290px"}}>
                    <Line
                        data={data}
                        width={100}
                        height={100}
                        options={
                            {
                                maintainAspectRatio : false,
                                elements: {
                                    point:{
                                        radius: 0
                                    }
                                },
                                legend: {
                                    position: "top",
                                    align: "start",
                                    labels: {
                                        boxWidth: 0,
                                        fontColor: '#25313C',
                                        fontSize: 14,
                                        fontStyle: "normal",
                                        fontWeight: "normal"
                                    }
                                },
                                scales: {
                                    yAxes: [{
                                        display: false,
                                        gridLines: {display: false},
                                        ticks: {
                                            beginAtZero: true,
                                            userCallback: function(label, index, labels) {
                                                if (Math.floor(label) === label) {
                                                    return label;
                                                }
                                            },
                                        }
                                    }],
                                },
                            }
                        }
                    />
                </div>
            </Col>
            <Col xl={4}  xs={12}  className="ps-3 ps-xl-0 mb-3" >
                <div className="bg-white h-100 p-5 d-flex align-items-center" style={{borderTopRightRadius: '10px', borderBottomRightRadius: '10px', minHeight: "290px"}}>
                    <Row>
                        <Col xs={6} md={6}>
                            <div className="tickets-infos">
                                <h5>Received</h5>
                                <span>{props.trends.receivedTickets ? props.trends.receivedTickets : 0}</span>
                            </div>
                        </Col>
                        <Col xs={6} md={6}>
                            <div className="tickets-infos">
                                <h5>Average first response time</h5>
                                <span>{props.trends.avgFirstResponseTime ? props.trends.avgFirstResponseTime : 0} </span>
                            </div>
                        </Col>
                        <Col xs={6} md={6}>
                            <div className="tickets-infos">
                                <h5>Closed</h5>
                                <span>{props.trends.closedTickets ? props.trends.closedTickets : 0}</span>
                            </div>
                        </Col>
                        <Col xs={6} md={6}>
                            <div className="tickets-infos">
                                <h5>Average response time</h5>
                                <span>{props.trends.avgResponseTime ? props.trends.avgResponseTime  : 0} </span>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Col>
        </>
    )
}
export default DashboardTrends
