import React from "react"
import {Line} from "react-chartjs-2";


const Busiest = ({dates}) => {

    const data = {
        labels: ["00:00", "01:00", "05:00", "06:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "23:00"],
        datasets: [
            {
                label: 'Monday',
                data: [10, 5, 3, 5, 2, 3, 5, 7, 12, 15 ,12, 2],
                fill: false,
                backgroundColor: '#fff',
                borderColor: '#27AE60',
                borderWidth: 2,
                hoverBackgroundColor: '#27AE60',
                pointBackgroundColor: "#27AE60",
            },
            {
                label: 'Tuesday',
                data: [1, 15, 1, 1, 2, 2, 1, 15, 1, 1, 2, 2],
                fill: false,
                backgroundColor: '#fff',
                borderColor: '#2D9CDB',
                borderWidth: 2,
                hoverBackgroundColor: '#2D9CDB',
                pointBackgroundColor: "#2D9CDB",
            },
            {
                label: 'Wednesday',
                data: [3, 2, 25, 4, 2, 2, 3, 2, 25, 4, 2, 2],
                fill: false,
                backgroundColor: '#fff',
                borderColor: '#F2994A',
                borderWidth: 2,
                hoverBackgroundColor: '#F2994A',
                pointBackgroundColor: "#F2994A",
            },
            {
                label: 'Thursday',
                data: [3, 2, 25, 4, 2, 2, 5, 7, 8, 12, 15, 12],
                fill: false,
                backgroundColor: '#fff',
                borderColor: '#9B51E0',
                borderWidth: 2,
                hoverBackgroundColor: '#9B51E0',
                pointBackgroundColor: "#9B51E0",
            },
            {
                label: 'Friday',
                data: [1, 15, 1, 1, 2, 2, 1, 15, 1, 1, 2, 2],
                fill: false,
                backgroundColor: '#fff',
                borderColor: '#56CCF2',
                borderWidth: 2,
                hoverBackgroundColor: '#56CCF2',
                pointBackgroundColor: "#56CCF2",
            },
            {
                label: 'Saturday',
                data: [10, 5, 3, 5, 2, 3, 10, 5, 3, 5, 2, 3],
                fill: false,
                backgroundColor: '#fff',
                borderColor: '#F2C94C',
                borderWidth: 2,
                hoverBackgroundColor: '#F2C94C',
                pointBackgroundColor: "#F2C94C",
            },
            {
                label: 'Sunday',
                data: [13, 4, 12, 5, 2, 3, 4, 8, 15, 2, 13, 4],
                fill: false,
                backgroundColor: '#fff',
                borderColor: '#EB5757',
                borderWidth: 2,
                hoverBackgroundColor: '#EB5757',
                pointBackgroundColor: "#EB5757",
            },
        ],
    };
    const options = {
        elements: {
            line: {
                tension: 0.1 // disables bezier curves
            }
        },
        scales: {
            yAxes: [
                {
                    type: 'linear',
                    position: 'left',
                    display: true,
                    gridLines: {display: true},
                    ticks: {
                        beginAtZero: true,
                        stepSize: 10,
                        userCallback: function(label, index, labels) {
                            // when the floored value is the same as the value we have a whole number
                            if (Math.floor(label) === label) {
                                return label;
                            }

                        },
                    }
                },

            ],
            xAxes: [
                {
                    gridLines: {
                        display: false,
                    },
                }
            ],
        },
        legend: {
            position: "top",
            align: "center",
            labels: {
                padding: 25,
                usePointStyle: true,
                pointStyle: 'circle',
                boxWidth: 8,
                fontColor: '#25313C',
                fontSize: 14,
                fontStyle: "normal",
                fontWeight: "normal",
            }
        },
        tooltips: {
            mode: 'index',
            intersect: false,
            usePointStyle: true,
            backgroundColor: '#fff',
            displayColors: true,
            boxShadow: "0px 10.2133px 30.6398px -7.65994px rgba(15, 34, 67, 0.16), 0px 1.27666px 3.82997px rgba(15, 34, 67, 0.12), 0px 0px 1.27666px rgba(15, 34, 67, 0.16);",
            callbacks: {
                labelTextColor: function(tooltipItem, chart){
                    let dataset = chart.config.data.datasets[tooltipItem.datasetIndex];
                    return {
                        color : dataset.backgroundColor
                    }
                },
            }
        },
        hover: {
            mode: 'index',
            intersect: false
        }
    };
    return(
        <div className="report-chart-holder">
            <h3 className="report-overview-h3">Busiest Time Of Day</h3>
            <Line
                data={data}
                options={options}
                width={100}
                height={30}
            />
        </div>
    )
}
export default Busiest
