import React from "react"

const MostExpensiveTickets = ({report}) => {
    const tickets = report && report.mostExpensiveTickets
    return(
        <div className="report-chart-holder mr-0">
            <div className="d-flex justify-content-between">
                <h4 className="report-chart-titles">Most Expensive Tickets </h4>
                <span>Count: {(tickets?.length || 0)}</span>
            </div>
            <div className="table-responsive">
                <table className="table performance-table table-striped">
                    <thead>
                    <tr>
                        <th>Ticket</th>
                        <th>TIME SPENT</th>
                        <th>NUMBER OF AGENTS</th>
                    </tr>
                    </thead>
                    <tbody>
                    {tickets ? tickets.map(ticket => (
                        <tr key={ticket.title}>
                            <td className="add-three-dots-p">{ticket.title}</td>
                            <td>{ticket.time_spend}</td>
                            <td>{ticket.agents_count}</td>
                        </tr>
                    )): <tr><td></td><td className="text-center">No Data</td><td></td></tr>}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
export default MostExpensiveTickets
