import React from "react"
import FilterComponent from "../Filter/FilterComponent";
import {Col, Row} from "react-bootstrap";
import TicketActivity from "./Ticket_Activity";
import StatusActivity from "./Status_Activity";
import Busiest from "./Busiest";
import PopularTags from "./Popular_tags";
import TagTimeSeries from "./tag_timeseries";
import ExpensiveCustomers from "./Expensive_Customers";
import ExpensiveTickets from "./Expensive_Tickets";
import {useSelector} from "react-redux";
import MainReport from "../MainReport";
import {fetchTicketsReport} from "../../../../redux/actions";

const TicketsReports = () => {
    const dates = useSelector(state => state?.getDatesReducer?.dates ?? []);
    const report = useSelector(state => state?.TicketsReducerReport?.data ?? []);

    return(
        <MainReport title={"Tickets Reports"} reqFrom="tickets">
            <Row>
                <Col xs={12} sm={9}>
                    <Row>
                        <Col xs={12} sm={6}>
                            <TicketActivity dates={dates} report={report}/>
                        </Col>
                        <Col xs={12} sm={6}>
                            <StatusActivity dates={dates} report={report}/>
                        </Col>
                    </Row>
                    {/*<Row>*/}
                    {/*    <Col xs={12}>*/}
                    {/*        <Busiest dates={dates}/>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                    <Row>
                        <Col xs={12} sm={6}>
                            <PopularTags dates={dates} report={report}/>
                        </Col>
                        <Col xs={12} sm={6}>
                            <TagTimeSeries dates={dates} report={report}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <ExpensiveTickets dates={dates} report={report}/>
                        </Col>
                        {/*<Col xs={12} xl={5}>*/}
                        {/*    <ExpensiveCustomers dates={dates}/>*/}
                        {/*</Col>*/}
                    </Row>
                </Col>
                <Col xs={12} sm={3}>
                    <FilterComponent url={fetchTicketsReport}/>
                </Col>
            </Row>
        </MainReport>
    )
}
export default TicketsReports
