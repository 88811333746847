import React from "react"

const TeamPerformance = ({report}) => {
    return(
        <div className="report-chart-holder mr-0">
            <h4 className="report-chart-titles">Team Performance</h4>
            <div className="table-responsive">
                <table className="table performance-table">
                    <thead>
                    <tr>
                        <th>Agent</th>
                        <th>Replies</th>
                        <th>Solved</th>
                        {/*<th>Happiness</th>*/}
                    </tr>
                    </thead>
                    <tbody>
                    {report && report.data && report.data.teamPerformance.length > 0 ? report.data.teamPerformance.map(perform => (
                        <tr key={perform.id}>
                            <td>
                                <div>
                                    <span>{report.data.teamPerformance.indexOf(perform) + 1}</span>
                                    <img src={perform.profile} alt="user image"/>
                                    <h3>{perform.name}</h3>
                                </div>
                            </td>
                            <td>
                                {perform.replies}
                            </td>
                            <td>
                                {perform.solved}
                            </td>
                            {/*<td>*/}
                            {/*    {perform.happiness}*/}
                            {/*</td>*/}
                        </tr>
                    )) : <tr><td></td><td className="text-center">Nothing to show</td><td></td></tr>}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
export default TeamPerformance
