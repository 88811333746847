import React, {useEffect} from "react"
import CustomProgressBar from "../../../../helpers/CustomProgressBar";
import Loader from "react-loader-spinner";

const PopularTags = ({report}) => {
    return(
        <div className="report-chart-holder mr-0">
            <h3 className="report-overview-h3">Popular Tags</h3>
            <div>
                <div className="report-popular-tags">
                    {report && report.data ? report.data.popularTags.map(tag => (
                        <div key={`tag${tag.id}`}>
                            <div className="d-flex align-items-center justify-content-between">
                                <h6>{tag.name}</h6>
                                {/*<span>45 (45%) </span>*/}
                            </div>
                            <CustomProgressBar now={45} label={`45%`} color={tag.color ? tag.color : "#c9c6c6"}/>
                        </div>
                    )) : 'No popular Tags'}
                </div>
            </div>
        </div>
    )
}
export default PopularTags
