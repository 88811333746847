import axios from "axios";
import { baseURL } from "./baseURL";
import Cookies from "js-cookie";
import failAlert from "./failAlert";
import successAlert from "./successAlert";

//  handle cancel request
const signalList = new Map();

export const headers = {
	headers: { Authorization: `Bearer ${Cookies.get('access_token')}`, 'Content-Type': 'application/json' }
}

const redirectOnFailure = (error) => {
	if(axios.isCancel(error)){
		return;
	}
	if (error.response.status === 401) { // not logged in or deactivated user
		Cookies.remove('access_token')
		Cookies.remove('mydev_token')
		localStorage.clear()
		window.location.href = '/'
	} else if (error.response.status === 403) { // email verification
		if (window.location.pathname!== '/verify-email') {
			location.href = '/verify-email'
		}
	} else if (error.response.status === 404) { // not found
		location.href = '/404'
	} else if (error.response.status === 500) { // server error
		location.href = '/500'
	}
}

export function postRequestCallback(url, data, callbackFunc, errorCallbackFunc = () => {}) {
	axios.post(`${baseURL}${url}`, data, headers)
		.then(() => {
			callbackFunc()
			console.log('post request', url, data);
			successAlert("Action completed");
		})
		.catch((error) => {
			errorCallbackFunc()
			failAlert(error.response.data.message ?? "Something went wrong");
			redirectOnFailure(error)
		})
}

export function postRequest(url, callBackFunc, requestData, dispatch) {
	return new Promise((resolve, reject) => {
		axios.post(`${baseURL}${url}`, requestData, headers)
			.then((response) => {
				dispatch(callBackFunc(response.data));
				successAlert("Action completed");
				resolve(response.data);
			})
			.catch((error) => {
				failAlert(error.response.data.message ?? "Something went wrong");
				redirectOnFailure(error)
				reject(error);
			});
	});
}

export function getRequest(url, callBackFunc, dispatch, options = {}) {
	const headers = {
		Authorization: `Bearer ${Cookies.get('access_token')}`
	};
	let cancelTokenSource = null;

	if (options?.signalId) {
		const prevSignal = signalList.get(options.signalId);
		if (prevSignal) {
			prevSignal.cancel('Operation canceled by the user.');
		}
		cancelTokenSource = axios.CancelToken.source();
		signalList.set(options.signalId, cancelTokenSource);
	}

	axios.get(`${baseURL}${url}`, { headers, cancelToken: cancelTokenSource?.token })
		.then(response => {
			if (response.data.data) { // backend controller must be fixed. There should not be a nested data object.
				dispatch(callBackFunc(response.data.data))
			} else {
				dispatch(callBackFunc(response.data))
			}
		})
		.catch(function (error) {
			redirectOnFailure(error)
		});
}